import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { useMutation } from '@apollo/client';

import {
  createLeadMutation,
  SAVE_DIGGER_ID_MUTATION
} from '../../../../../client/__graphql__/mutations';
import {
  ILeadInput,
  ILeadResponse,
  ISaveDiggerInput,
  ISaveDiggerResponse
} from '../../../../__types__/global';

import { SiteContext } from '../../../../utils/context/SiteContext';
import { useForm } from '../../../../utils/hooks/useForm';
import { useFormTransition } from '../../../../utils/hooks/useFormTransition';

import { Section } from '../../../layout/Section';
import { Container } from '../../../layout/Container';
import { BackgroundLoad } from '../../../layout/BackgroundLoad';
import { Thanks } from '../../../layout/Thanks';

import { Input } from '../../../ui/form/Input';
import { FormError } from '../../../ui/form/FormError';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { Form, FormRow } from '../../../ui/form/Form';
import { ButtonGroup } from '../../../ui/button/ButtonGroup';
import { Button } from '../../../ui/button/Button';
import { ButtonLink } from '../../../ui/link/ButtonLink';

import { AccordionGeneral } from '../../../partials/AccordionGeneral';
import { AccordionBenefits } from '../../../partials/AccordionBenefits';
import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import { Seo } from '../../../../utils/seo/Index';

import { getDiggerData } from '../../../../utils/tracking/digger';
import { createGTMEvent } from '../../../../utils/tracking/gtm';
import { DiggerType, useDiggerId } from '../../../../utils/hooks/useDiggerId';

interface IRoute {
  office: string;
  path: string;
}

const MellomLinjene: React.FC<RouteComponentProps<IRoute>> = ({
  location,
  match
}) => {
  const { routeConfig }: any = React.useContext(SiteContext);
  const { submitWithDigger } = useDiggerId();
  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });

  const [posted, setPosted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const VALUATION_MUTATION = createLeadMutation({
    valuation: {
      response: `{
        success
        data {
          id
          address
          zip
          phone
          email
        }
      }`
    }
  });

  const [lead, { error }] = useMutation<ILeadResponse, ILeadInput>(
    VALUATION_MUTATION,
    {
      onCompleted: ({ lead }) => {
        setLoading(false);

        if (lead.valuation?.success) {
          window.scroll(0, 0);
          setPosted(true);
          // GTM tracking

          createGTMEvent({
            event: 'pmFormSubmission',
            gaEvent: 'Kampanje - mellom linjene',
            gaCategory: 'Form',
            gaAction: 'Submit'
          });
        }
      }
    }
  );

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'address',
        placeholder: 'Eks. Adresseveien 23',
        value: '',
        label: 'Adresse'
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: 'Eks. 1234',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eks. ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      }
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);

      setLoading(true);
      submitWithDigger(
        DiggerType.VERDIVURDERING,
        {
          zip: preparedFields.zip
        },
        (data) => {
          lead({
            variables: {
              input: {
                ...preparedFields,
                diggerId: data.diggerId,
                referer: location.pathname,
                leadType: 'VERDIVURDERING',
                pageType: 'GENERELL'
              }
            }
          });
        }
      );
    }
  });

  return (
    <>
      <Seo
        title="Mellom linjene - PrivatMegleren"
        description="Når det kommer til det mest verdifulle du eier, skal du ha høye forventninger"
        url={location.pathname}
      />
      <Top />
      <Container style={pageFadeIn}>
        <FormWrapper style={fadeOut}>
          <Section
            className="form-section"
            style={{ paddingTop: '0', paddingBottom: '10px' }}
          >
            <Paragraph center={true} className="form-text">
              Det er hva eiendommen kan innfri av ønsker og behov, som avgjør
              hvor mye den er verdt for den enkelte. Vi leser mellom linjene for
              å finne ut hva eiendomskjøpere egentlig ser etter. Ved å forstå
              hele verdien av din eiendom, kan vi bedre møte behovene til de som
              kommer på visning. Det er en av grunnene til at vi selger flere
              eiendommer enn noen andre*. Forvent en megler som lytter.{' '}
              <span style={{ color: '#FF0021', fontWeight: 'bold' }}>
                Forvent mer.
              </span>
            </Paragraph>
            <Paragraph center style={{ fontSize: '0.9em' }}>
              * PrivatMegleren selger flest eiendommer i Norge målt per megler.
            </Paragraph>
            <Paragraph center>
              Lurer du på hva din eiendom er verdt? Fyll inn skjema under og en
              eiendomsmegler kontakter deg.
            </Paragraph>
          </Section>
          <Form
            onSubmit={handleSubmit}
            noValidate
            style={{ paddingBottom: '50px' }}
          >
            {error &&
              error.graphQLErrors.map((error, index) => (
                <FormError key={`error_${index}`} message={error.message} />
              ))}
            {fields.map((item, index) => {
              return (
                <FormRow key={`field_${index}`}>
                  <Input
                    type={item.type}
                    name={item.name}
                    label={item.label}
                    labelAfter={item.labelAfter}
                    placeholder={item.placeholder}
                    value={item.value}
                    onChange={handleChange}
                    error={item.error}
                  />
                </FormRow>
              );
            })}
            <ButtonGroup>
              <Button
                type="primary"
                colorTheme="gold"
                disabled={loading}
                loading={loading}
              >
                Kontakt meg
              </Button>
              <ButtonLink
                as="a"
                href={routeConfig.personvern.url}
                className="privacy-link-mobile"
              >
                Personvernpolicy
              </ButtonLink>
            </ButtonGroup>
          </Form>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h1" center={true}>
              Tusen takk
            </Heading>
            <Paragraph center={true}>
              En eiendomsmegler vil ta kontakt med deg så snart som mulig.
            </Paragraph>
            <AccordionGeneral />
          </Section>
          <Section className="form-section" style={{ paddingTop: 0 }}>
            <AccordionBenefits />
          </Section>
        </Thanks>
      </Container>
    </>
  );
};

export default MellomLinjene;

const FormWrapper = styled(animated.div)``;

const Top = () => {
  const animatedProps = useSpring({
    from: { opacity: 0, transform: 'translateY(-45px)' },
    to: { opacity: 1, transform: 'translateY(0)' }
  });

  return (
    <StyledTop style={animatedProps}>
      <video
        className="video-desktop"
        loop
        muted
        playsInline
        autoPlay
        style={{ width: '100%' }}
      >
        <source
          src="https://cdn.reeltime.no/pm_assets/kampanjer/mellomlinjene/mellom-linjene.mp4"
          type="video/mp4"
        />
      </video>
      <video
        className="video-mobil"
        loop
        muted
        playsInline
        autoPlay
        style={{ width: '100%' }}
      >
        <source
          src="https://cdn.reeltime.no/pm_assets/kampanjer/mellomlinjene/mellom-linjene-mobil.mp4"
          type="video/mp4"
        />
      </video>
    </StyledTop>
  );
};

const StyledTop = styled(animated.div)`
  margin-top: 66px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 3em;

  img {
    width: 100%;
    max-height: 500px;
    object-fit: contain;
    object-position: center;
  }

  .video-mobil {
    display: block;
  }
  .video-desktop {
    display: none;
  }

  @media (min-width: 600px) {
    .video-mobil {
      display: none;
    }
    .video-desktop {
      display: block;
    }
  }

  @media all and (max-width: 1280px) {
    img {
      min-height: 300px;
      object-fit: cover;
    }
  }
`;
